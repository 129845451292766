:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pos-absolute{position:absolute;}
:root ._t-0px{top:0px;}
:root ._l-0px{left:0px;}
:root ._r-0px{right:0px;}
:root ._b-0px{bottom:0px;}
:root ._mt-auto{margin-top:auto;}
:root ._mr-auto{margin-right:auto;}
:root ._mb-auto{margin-bottom:auto;}
:root ._ml-auto{margin-left:auto;}
:root ._w-9437{width:94%;}
:root ._h-9437{height:94%;}
:root ._bg-137133827{background-color:var(--color-1);}
:root ._btlr-9999px{border-top-left-radius:9999px;}
:root ._btrr-9999px{border-top-right-radius:9999px;}
:root ._bbrr-9999px{border-bottom-right-radius:9999px;}
:root ._bblr-9999px{border-bottom-left-radius:9999px;}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-500{font-weight:500;}
:root ._ls-167743997{letter-spacing:var(--f-21-2);}
:root ._fos-229441158{font-size:var(--f-si-2);}
:root ._lh-222976511{line-height:var(--f-li-2);}
:root ._ta-center{text-align:center;}
@media screen and (min-width: 801px) { :root:root:root ._col-_gtSm_137133982{color:var(--color-6);} }
@media screen and (min-width: 801px) { :root:root:root ._ff-_gtSm_299667014{font-family:var(--f-fa);} }
@media screen and (min-width: 801px) { :root:root:root ._fow-_gtSm_233016171{font-weight:var(--f-we-5);} }
@media screen and (min-width: 801px) { :root:root:root ._ls-_gtSm_167744090{letter-spacing:var(--f-21-5);} }
@media screen and (min-width: 801px) { :root:root:root ._fos-_gtSm_229441251{font-size:var(--f-si-5);} }
@media screen and (min-width: 801px) { :root:root:root ._lh-_gtSm_222976604{line-height:var(--f-li-5);} }
:root ._col-137133982{color:var(--color-6);}