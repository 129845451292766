:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-1{flex-shrink:1;}
:root ._pt-56d0t251463{padding-top:56.25%;}
:root ._fg-1{flex-grow:1;}
:root ._btlr-1307609967{border-top-left-radius:var(--radius-4);}
:root ._btrr-1307609967{border-top-right-radius:var(--radius-4);}
:root ._bbrr-1307609967{border-bottom-right-radius:var(--radius-4);}
:root ._bblr-1307609967{border-bottom-left-radius:var(--radius-4);}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._fs-0{flex-shrink:0;}
:root ._pos-absolute{position:absolute;}
:root ._t-1481558400{top:var(--space-1);}
:root ._l-1481558400{left:var(--space-1);}
:root ._w-10037{width:100%;}
:root ._h-10037{height:100%;}
:root ._zi-1{z-index:1;}
:root ._bg-43817440{background-color:var(--color-20);}