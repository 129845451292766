:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pos-relative{position:relative;}
:root ._bg-43818122{background-color:var(--color-19);}
:root ._jc-center{justify-content:center;}
:root ._ai-center{align-items:center;}
:root ._pt-1316331292{padding-top:var(--space-27);}
:root ._pr-1316331292{padding-right:var(--space-27);}
:root ._pb-1316331292{padding-bottom:var(--space-27);}
:root ._pl-1316331292{padding-left:var(--space-27);}
:root ._gap-1316331292{gap:var(--space-27);}
@media screen and (min-width: 801px) { :root:root:root ._pr-_gtSm_1481558214{padding-right:var(--space-7);} }
@media screen and (min-width: 801px) { :root:root:root ._pl-_gtSm_1481558214{padding-left:var(--space-7);} }
@media screen and (min-width: 801px) { :root:root:root ._pt-_gtSm_1481558276{padding-top:var(--space-5);} }
@media screen and (min-width: 801px) { :root:root:root ._pb-_gtSm_1481558276{padding-bottom:var(--space-5);} }