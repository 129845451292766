:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pos-relative{position:relative;}
:root ._bg-43818122{background-color:var(--color-19);}
:root ._gap-1316331292{gap:var(--space-27);}
:root ._jc-center{justify-content:center;}
:root ._ai-center{align-items:center;}
:root ._pt-1316331292{padding-top:var(--space-27);}
:root ._pr-1316331292{padding-right:var(--space-27);}
:root ._pb-1316331292{padding-bottom:var(--space-27);}
:root ._pl-1316331292{padding-left:var(--space-27);}
:root ._btlr-1307609967{border-top-left-radius:var(--radius-4);}
:root ._btrr-1307609967{border-top-right-radius:var(--radius-4);}
:root ._bbrr-1307609967{border-bottom-right-radius:var(--radius-4);}
:root ._bblr-1307609967{border-bottom-left-radius:var(--radius-4);}
:root ._ai-stretch{align-items:stretch;}
:root ._w-36px{width:36px;}
:root ._h-57px{height:57px;}
@media screen and (min-width: 801px) { :root:root:root ._w-_gtSm_63px{width:63px;} }
@media screen and (min-width: 801px) { :root:root:root ._h-_gtSm_103px{height:103px;} }
:root ._gap-1481558338{gap:var(--space-3);}