:root ._dsp-inline{display:inline;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-233016295{font-weight:var(--f-we-9);}
:root ._ls-167744214{letter-spacing:var(--f-21-9);}
:root ._fos-229441313{font-size:var(--f-si-7);}
:root ._lh-222976697{line-height:var(--f-li-8);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
@media screen and (min-width: 801px) { :root:root:root ._fos-_gtSm_229441344{font-size:var(--f-si-8);} }
:root ._col-137133827{color:var(--color-1);}
:root ._tt-uppercase{text-transform:uppercase;}