:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._jc-center{justify-content:center;}
:root ._mt-1316332098{margin-top:var(--space-32);}
:root ._mr-1316332098{margin-right:var(--space-32);}
:root ._mb-1316332098{margin-bottom:var(--space-32);}
:root ._ml-1316332098{margin-left:var(--space-32);}
:root ._fw-wrap{flex-wrap:wrap;}
@media screen and (min-width: 476px) { :root:root:root ._fd-_gtXxs_row{flex-direction:row;} }
:root ._w-10037{width:100%;}
:root ._fg-1{flex-grow:1;}
:root ._fs-1{flex-shrink:1;}
:root ._pt-1481558338{padding-top:var(--space-3);}
:root ._pr-1481558338{padding-right:var(--space-3);}
:root ._pb-1481558338{padding-bottom:var(--space-3);}
:root ._pl-1481558338{padding-left:var(--space-3);}
@media screen and (min-width: 476px) { :root:root:root ._w-_gtXxs_5037{width:50%;} }
@media screen and (min-width: 476px) { :root:root:root:root ._maw-_gtXxs_500px{max-width:500px;} }
@media screen and (min-width: 476px) { :root:root:root:root:root ._maw-_gtXxs_auto{max-width:auto;} }