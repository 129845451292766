:root ._dsp-inline{display:inline;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-137133827{color:var(--color-1);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-500{font-weight:500;}
:root ._ls-167744090{letter-spacing:var(--f-21-5);}
:root ._fos-229441251{font-size:var(--f-si-5);}
:root ._lh-222976604{line-height:var(--f-li-5);}
:root ._ta-center{text-align:center;}