:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pt-1481558338{padding-top:var(--space-3);}
:root ._pb-1481558338{padding-bottom:var(--space-3);}
:root ._bg-43817440{background-color:var(--color-20);}
@media screen and (min-width: 801px) { :root:root:root ._pt-_gtSm_1481558307{padding-top:var(--space-4);} }
@media screen and (min-width: 801px) { :root:root:root ._pb-_gtSm_1481558307{padding-bottom:var(--space-4);} }
:root ._w-5037{width:50%;}