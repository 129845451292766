:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._w-10037{width:100%;}
:root ._bg-43818122{background-color:var(--color-19);}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._btw-1px{border-top-width:1px;}
:root ._brw-1px{border-right-width:1px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-1px{border-left-width:1px;}
:root ._btlr-1307609936{border-top-left-radius:var(--radius-3);}
:root ._btrr-1307609936{border-top-right-radius:var(--radius-3);}
:root ._bbrr-1307609936{border-bottom-right-radius:var(--radius-3);}
:root ._bblr-1307609936{border-bottom-left-radius:var(--radius-3);}
:root ._btc-43817440{border-top-color:var(--color-20);}
:root ._brc-43817440{border-right-color:var(--color-20);}
:root ._bbc-43817440{border-bottom-color:var(--color-20);}
:root ._blc-43817440{border-left-color:var(--color-20);}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._bxsh-568822388{box-shadow:0px 6px 12px var(--shadowColor);}
:root ._fd-row{flex-direction:row;}
:root ._ai-center{align-items:center;}
:root ._pos-absolute{position:absolute;}
:root ._b-0px{bottom:0px;}
:root ._r-0px{right:0px;}
:root ._pr-1481558338{padding-right:var(--space-3);}
:root ._pl-1481558338{padding-left:var(--space-3);}
:root ._pt-1481558369{padding-top:var(--space-2);}
:root ._pb-1481558369{padding-bottom:var(--space-2);}
:root ._zi-982430091{z-index:var(--zIndex-2);}
:root ._bg-d0t1880910726{background-color:rgba(0,0,0,0.5);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-137133982{color:var(--color-6);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-500{font-weight:500;}
:root ._ls-167744059{letter-spacing:var(--f-21-4);}
:root ._fos-229441158{font-size:var(--f-si-2);}
:root ._lh-222976573{line-height:var(--f-li-4);}
:root ._col-43818401{color:var(--color-10);}
:root ._fow-233016264{font-weight:var(--f-we-8);}
:root ._ls-167744183{letter-spacing:var(--f-21-8);}
:root ._fos-229441220{font-size:var(--f-si-4);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}