:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._gap-1316331292{gap:var(--space-27);}
:root ._fd-row{flex-direction:row;}
:root ._ai-flex-start{align-items:flex-start;}
:root ._jc-flex-start{justify-content:flex-start;}
:root ._pr-1481558307{padding-right:var(--space-4);}
@media screen and (max-width: 660px) { :root:root:root ._pr-_xs_1481558400{padding-right:var(--space-1);} }
:root ._h-10037{height:100%;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}