:root ._w-10037{width:100%;}
:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._maw-768px{max-width:768px;}
:root ._mr-auto{margin-right:auto;}
:root ._ml-auto{margin-left:auto;}
:root ._gap-1481558338{gap:var(--space-3);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-43818401{color:var(--color-10);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-700{font-weight:700;}
:root ._ls-167744183{letter-spacing:var(--f-21-8);}
:root ._fos-229441344{font-size:var(--f-si-8);}
:root ._lh-222976697{line-height:var(--f-li-8);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._ta-center{text-align:center;}
:root ._tt-uppercase{text-transform:uppercase;}
@media screen and (min-width: 801px) { :root:root:root ._col-_gtSm_43818401{color:var(--color-10);} }
@media screen and (min-width: 801px) { :root:root:root ._ff-_gtSm_299667014{font-family:var(--f-fa);} }
@media screen and (min-width: 801px) { :root:root:root ._fow-_gtSm_700{font-weight:700;} }
@media screen and (min-width: 801px) { :root:root:root ._ls-_gtSm_905095908{letter-spacing:var(--f-21-10);} }
@media screen and (min-width: 801px) { :root:root:root ._fos-_gtSm_1477259397{font-size:var(--f-si-10);} }
@media screen and (min-width: 801px) { :root:root:root ._lh-_gtSm_1677663454{line-height:var(--f-li-10);} }
:root ._fow-233016109{font-weight:var(--f-we-3);}
:root ._ls-167744028{letter-spacing:var(--f-21-3);}
:root ._fos-229441189{font-size:var(--f-si-3);}
:root ._lh-222976542{line-height:var(--f-li-3);}
@media screen and (min-width: 661px) { :root:root:root ._col-_gtXs_43818401{color:var(--color-10);} }
@media screen and (min-width: 661px) { :root:root:root ._ff-_gtXs_299667014{font-family:var(--f-fa);} }
@media screen and (min-width: 661px) { :root:root:root ._fow-_gtXs_233016202{font-weight:var(--f-we-6);} }
@media screen and (min-width: 661px) { :root:root:root ._ls-_gtXs_167744121{letter-spacing:var(--f-21-6);} }
@media screen and (min-width: 661px) { :root:root:root ._fos-_gtXs_229441282{font-size:var(--f-si-6);} }
@media screen and (min-width: 661px) { :root:root:root ._lh-_gtXs_222976635{line-height:var(--f-li-6);} }