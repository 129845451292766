:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-43817347{color:var(--color-23);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-233016140{font-weight:var(--f-we-4);}
:root ._ls-167744059{letter-spacing:var(--f-21-4);}
:root ._fos-229441220{font-size:var(--f-si-4);}
:root ._lh-222976573{line-height:var(--f-li-4);}
:root ._ta-center{text-align:center;}