:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-row{flex-direction:row;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._btw-2px{border-top-width:2px;}
:root ._brw-2px{border-right-width:2px;}
:root ._bbw-2px{border-bottom-width:2px;}
:root ._blw-2px{border-left-width:2px;}
:root ._btlr-1307609936{border-top-left-radius:var(--radius-3);}
:root ._btrr-1307609936{border-top-right-radius:var(--radius-3);}
:root ._bbrr-1307609936{border-bottom-right-radius:var(--radius-3);}
:root ._bblr-1307609936{border-bottom-left-radius:var(--radius-3);}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._btc-137133827{border-top-color:var(--color-1);}
:root ._brc-137133827{border-right-color:var(--color-1);}
:root ._bbc-137133827{border-bottom-color:var(--color-1);}
:root ._blc-137133827{border-left-color:var(--color-1);}
:root ._btc-43817161{border-top-color:var(--color-29);}
:root ._brc-43817161{border-right-color:var(--color-29);}
:root ._bbc-43817161{border-bottom-color:var(--color-29);}
:root ._blc-43817161{border-left-color:var(--color-29);}
:root ._h-50px{height:50px;}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._bg-43818153{background-color:var(--color-18);}
:root ._w-10037{width:100%;}
:root ._pr-1316331292{padding-right:var(--space-27);}
:root ._pl-1316331292{padding-left:var(--space-27);}
:root ._ai-center{align-items:center;}
:root ._jc-441309761{justify-content:space-between;}
:root ._zi-982430091{z-index:var(--zIndex-2);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-233016140{font-weight:var(--f-we-4);}
:root ._ls-167744059{letter-spacing:var(--f-21-4);}
:root ._fos-229441220{font-size:var(--f-si-4);}
:root ._lh-222976573{line-height:var(--f-li-4);}
:root ._col-43816448{color:var(--color-31);}
:root ._fow-233016202{font-weight:var(--f-we-6);}
:root ._ls-167744121{letter-spacing:var(--f-21-6);}
:root ._fos-229441282{font-size:var(--f-si-6);}
:root ._lh-222976635{line-height:var(--f-li-6);}
@media screen and (max-width: 660px) { :root:root:root ._fos-_xs_229441220{font-size:var(--f-si-4);} }
@media screen and (max-width: 660px) { :root:root:root ._lh-_xs_222976573{line-height:var(--f-li-4);} }
:root ._fow-233016078{font-weight:var(--f-we-2);}
:root ._ls-167743997{letter-spacing:var(--f-21-2);}
:root ._fos-229441158{font-size:var(--f-si-2);}
:root ._lh-222976511{line-height:var(--f-li-2);}
@media screen and (max-width: 660px) { :root:root:root ._col-_xs_43816448{color:var(--color-31);} }
@media screen and (max-width: 660px) { :root:root:root ._ff-_xs_299667014{font-family:var(--f-fa);} }
@media screen and (max-width: 660px) { :root:root:root ._fow-_xs_233016078{font-weight:var(--f-we-2);} }
@media screen and (max-width: 660px) { :root:root:root ._ls-_xs_167743997{letter-spacing:var(--f-21-2);} }
@media screen and (max-width: 660px) { :root:root:root ._fos-_xs_229441158{font-size:var(--f-si-2);} }
@media screen and (max-width: 660px) { :root:root:root ._lh-_xs_222976511{line-height:var(--f-li-2);} }
:root ._fd-column{flex-direction:column;}
:root ._gap-1481558338{gap:var(--space-3);}
:root ._col-675002279{color:var(--color);}
:root ._ta-center{text-align:center;}
:root ._col-43818122{color:var(--color-19);}
:root ._col-43818401{color:var(--color-10);}