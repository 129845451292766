:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pos-relative{position:relative;}
:root ._jc-center{justify-content:center;}
:root ._ai-center{align-items:center;}
:root ._w-10037{width:100%;}
:root ._gap-1481558307{gap:var(--space-4);}
:root ._bg-43817440{background-color:var(--color-20);}
:root ._pb-1481558307{padding-bottom:var(--space-4);}
@media screen and (min-width: 801px) { :root:root:root ._pb-_gtSm_1481558400{padding-bottom:var(--space-1);} }
:root ._pe-none{pointer-events:none !important;}
@media screen and (min-width: 801px) { :root:root:root ._bg-_gtSm_43816417{background-color:var(--color-32);} }
@media screen and (min-width: 801px) { :root:root:root ._pos-_gtSm_absolute{position:absolute;} }
@media screen and (min-width: 801px) { :root:root:root ._h-_gtSm_10037{height:100%;} }