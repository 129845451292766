:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pos-relative{position:relative;}
:root ._bg-43818122{background-color:var(--color-19);}
:root ._btlr-1307609967{border-top-left-radius:var(--radius-4);}
:root ._btrr-1307609967{border-top-right-radius:var(--radius-4);}
:root ._bbrr-1307609967{border-bottom-right-radius:var(--radius-4);}
:root ._bblr-1307609967{border-bottom-left-radius:var(--radius-4);}
:root ._pos-absolute{position:absolute;}
:root ._t-0px{top:0px;}
:root ._l-0px{left:0px;}
:root ._r-0px{right:0px;}
:root ._w-10037{width:100%;}
:root ._h-10037{height:100%;}
:root ._o-0d0t546{opacity:0.5;}
:root ._gap-1481558214{gap:var(--space-7);}
:root ._gap-1316331292{gap:var(--space-27);}
:root ._jc-center{justify-content:center;}
:root ._ai-center{align-items:center;}
:root ._pt-1316331292{padding-top:var(--space-27);}
:root ._pr-1316331292{padding-right:var(--space-27);}
:root ._pb-1316331292{padding-bottom:var(--space-27);}
:root ._pl-1316331292{padding-left:var(--space-27);}
:root ._gap-1481558338{gap:var(--space-3);}
:root ._gap-1481558307{gap:var(--space-4);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-auto{margin-right:auto;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-auto{margin-left:auto;}
:root ._col-137133827{color:var(--color-1);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-600{font-weight:600;}
:root ._ls-167744152{letter-spacing:var(--f-21-7);}
:root ._fos-229441313{font-size:var(--f-si-7);}
:root ._lh-222976666{line-height:var(--f-li-7);}
:root ._maw-400px{max-width:400px;}
:root ._ta-center{text-align:center;}
:root ._tt-uppercase{text-transform:uppercase;}
@media screen and (min-width: 376px) { :root:root:root ._fd-_gtXxxs_row{flex-direction:row;} }
:root ._mr-0px{margin-right:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ls-167744059{letter-spacing:var(--f-21-4);}
:root ._fos-229441220{font-size:var(--f-si-4);}
:root ._lh-222976573{line-height:var(--f-li-4);}
:root ._col-43818401{color:var(--color-10);}
:root ._fow-233016140{font-weight:var(--f-we-4);}