:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pos-relative{position:relative;}
:root ._bg-43818122{background-color:var(--color-19);}
:root ._gap-1481558152{gap:var(--space-9);}
:root ._jc-center{justify-content:center;}
:root ._ai-center{align-items:center;}
:root ._pt-1316331292{padding-top:var(--space-27);}
:root ._pr-1316331292{padding-right:var(--space-27);}
:root ._pb-1316331292{padding-bottom:var(--space-27);}
:root ._pl-1316331292{padding-left:var(--space-27);}
:root ._btlr-1307609967{border-top-left-radius:var(--radius-4);}
:root ._btrr-1307609967{border-top-right-radius:var(--radius-4);}
:root ._bbrr-1307609967{border-bottom-right-radius:var(--radius-4);}
:root ._bblr-1307609967{border-bottom-left-radius:var(--radius-4);}
:root ._ai-stretch{align-items:stretch;}
:root ._gap-1481558369{gap:var(--space-2);}
:root ._gap-1316331292{gap:var(--space-27);}
:root ._w-10037{width:100%;}
:root ._fd-row{flex-direction:row;}
:root ._bg-43818153{background-color:var(--color-18);}
:root ._jc-441309761{justify-content:space-between;}
:root ._pos-absolute{position:absolute;}
:root ._l-0px{left:0px;}
:root ._r-0px{right:0px;}
:root ._t--2537{top:-25%;}
:root ._mr-auto{margin-right:auto;}
:root ._ml-auto{margin-left:auto;}
:root ._pt-1481558338{padding-top:var(--space-3);}
:root ._pr-1481558338{padding-right:var(--space-3);}
:root ._pb-1481558338{padding-bottom:var(--space-3);}
:root ._pl-1481558338{padding-left:var(--space-3);}
:root ._bg-137134044{background-color:var(--color-8);}
:root ._fs-1{flex-shrink:1;}
:root ._gap-1481558338{gap:var(--space-3);}
:root ._fg-1{flex-grow:1;}