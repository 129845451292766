:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._gap-1481558307{gap:var(--space-4);}
@media screen and (min-width: 476px) { :root:root:root ._gap-_gtXxs_1316331292{gap:var(--space-27);} }
:root ._gap-1481558338{gap:var(--space-3);}