:root ._dsp-inline{display:inline;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-43818401{color:var(--color-10);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-233016140{font-weight:var(--f-we-4);}
:root ._ls-167744059{letter-spacing:var(--f-21-4);}
:root ._fos-229441220{font-size:var(--f-si-4);}
:root ._lh-222976573{line-height:var(--f-li-4);}
:root ._fs-1{flex-shrink:1;}
:root ._tt-uppercase{text-transform:uppercase;}
@media screen and (min-width: 661px) { :root:root:root ._col-_gtXs_43818401{color:var(--color-10);} }
@media screen and (min-width: 661px) { :root:root:root ._ff-_gtXs_299667014{font-family:var(--f-fa);} }
@media screen and (min-width: 661px) { :root:root:root ._fow-_gtXs_233016171{font-weight:var(--f-we-5);} }
@media screen and (min-width: 661px) { :root:root:root ._ls-_gtXs_167744090{letter-spacing:var(--f-21-5);} }
@media screen and (min-width: 661px) { :root:root:root ._fos-_gtXs_229441251{font-size:var(--f-si-5);} }
@media screen and (min-width: 661px) { :root:root:root ._lh-_gtXs_222976604{line-height:var(--f-li-5);} }
:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._h-auto{height:auto;}
:root ._h-0px{height:0px;}
:root ._pt-1481558307{padding-top:var(--space-4);}