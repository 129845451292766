:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-1{flex-shrink:1;}
:root ._gap-1481558307{gap:var(--space-4);}
:root ._pt-1316331292{padding-top:var(--space-27);}
:root ._pr-1316331292{padding-right:var(--space-27);}
:root ._pb-1316331292{padding-bottom:var(--space-27);}
:root ._pl-1316331292{padding-left:var(--space-27);}
:root ._bg-43818184{background-color:var(--color-17);}
:root ._btlr-1307609967{border-top-left-radius:var(--radius-4);}
:root ._btrr-1307609967{border-top-right-radius:var(--radius-4);}
:root ._bbrr-1307609967{border-bottom-right-radius:var(--radius-4);}
:root ._bblr-1307609967{border-bottom-left-radius:var(--radius-4);}
:root ._fg-1{flex-grow:1;}
@media screen and (min-width: 801px) { :root:root:root ._fd-_gtSm_row{flex-direction:row;} }
@media screen and (min-width: 801px) { :root:root:root ._ai-_gtSm_flex-start{align-items:flex-start;} }
@media screen and (min-width: 801px) { :root:root:root ._gap-_gtSm_1316331292{gap:var(--space-27);} }
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._fd-row{flex-direction:row;}
:root ._fs-0{flex-shrink:0;}
:root ._pos-absolute{position:absolute;}
:root ._jc-center{justify-content:center;}
:root ._bg-43818401{background-color:var(--color-10);}
:root ._pt-1481558338{padding-top:var(--space-3);}
:root ._pr-1481558338{padding-right:var(--space-3);}
:root ._pb-1481558338{padding-bottom:var(--space-3);}
:root ._pl-1481558338{padding-left:var(--space-3);}
:root ._zi-982430091{z-index:var(--zIndex-2);}
:root ._t-25px{top:25px;}
:root ._r--100px{right:-100px;}
:root ._w-300px{width:300px;}
:root ._transform-1585222481{transform:rotate(45deg);}
@media screen and (min-width: 801px) { :root:root:root ._t-_gtSm_unset{top:unset;} }
@media screen and (min-width: 801px) { :root:root:root ._r-_gtSm_unset{right:unset;} }
@media screen and (min-width: 801px) { :root:root:root ._b-_gtSm_25px{bottom:25px;} }
@media screen and (min-width: 801px) { :root:root:root ._l-_gtSm_-100px{left:-100px;} }
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-43816448{color:var(--color-31);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-500{font-weight:500;}
:root ._ls-167744121{letter-spacing:var(--f-21-6);}
:root ._fos-229441282{font-size:var(--f-si-6);}
:root ._lh-222976635{line-height:var(--f-li-6);}
:root ._ta-center{text-align:center;}
:root ._gap-1481558276{gap:var(--space-5);}
:root ._h-10037{height:100%;}
:root ._gap-1481558338{gap:var(--space-3);}
:root ._ls-167744183{letter-spacing:var(--f-21-8);}
:root ._fos-229441344{font-size:var(--f-si-8);}
:root ._lh-222976697{line-height:var(--f-li-8);}
:root ._col-43818246{color:var(--color-15);}
:root ._fow-300{font-weight:300;}
:root ._textDecorationLine-1171789332{text-decoration-line:line-through;}
:root ._fow-233016202{font-weight:var(--f-we-6);}
:root ._tt-uppercase{text-transform:uppercase;}
:root ._fow-233016109{font-weight:var(--f-we-3);}
:root ._ls-167744028{letter-spacing:var(--f-21-3);}
:root ._fos-229441189{font-size:var(--f-si-3);}
:root ._lh-222976542{line-height:var(--f-li-3);}