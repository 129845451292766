:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._w-250px{width:250px;}
@media screen and (max-width: 660px) { :root:root:root ._w-_xs_10037{width:100%;} }
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-675002279{color:var(--color);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-233016171{font-weight:var(--f-we-5);}
:root ._ls-167744090{letter-spacing:var(--f-21-5);}
:root ._fos-229441251{font-size:var(--f-si-5);}
:root ._lh-222976604{line-height:var(--f-li-5);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._fd-row{flex-direction:row;}
:root ._jc-441309761{justify-content:space-between;}
:root ._ai-center{align-items:center;}
:root ._h-40px{height:40px;}
:root ._fow-233016202{font-weight:var(--f-we-6);}
:root ._ls-167744121{letter-spacing:var(--f-21-6);}
:root ._fos-229441282{font-size:var(--f-si-6);}
:root ._lh-222976635{line-height:var(--f-li-6);}
:root ._fs-1{flex-shrink:1;}
:root ._fg-7{flex-grow:7;}
:root ._fow-233016140{font-weight:var(--f-we-4);}
:root ._ls-167744059{letter-spacing:var(--f-21-4);}
:root ._fos-229441220{font-size:var(--f-si-4);}
:root ._lh-222976573{line-height:var(--f-li-4);}
:root ._w-d0t1816237130{width:14.285714285714286%;}
:root ._ta-center{text-align:center;}
:root ._pt-1481558338{padding-top:var(--space-3);}
:root ._pb-1481558338{padding-bottom:var(--space-3);}
:root ._fw-wrap{flex-wrap:wrap;}