:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-row{flex-direction:row;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._w-8px{width:8px;}
:root ._h-8px{height:8px;}
:root ._btlr-1000px{border-top-left-radius:1000px;}
:root ._btrr-1000px{border-top-right-radius:1000px;}
:root ._bbrr-1000px{border-bottom-right-radius:1000px;}
:root ._bblr-1000px{border-bottom-left-radius:1000px;}
:root ._bg-137133827{background-color:var(--color-1);}
:root ._bg-43818153{background-color:var(--color-18);}
:root ._cur-pointer{cursor:pointer;}
:root:root  ._o-0active-0d0t746:active{opacity:0.7 !important;}
@media screen and (min-width: 801px) { :root:root:root ._w-_gtSm_11px{width:11px;} }
@media screen and (min-width: 801px) { :root:root:root ._h-_gtSm_11px{height:11px;} }