:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._gap-1481558338{gap:var(--space-3);}
:root ._w-10037{width:100%;}
:root ._pt-2px{padding-top:2px;}
:root ._pr-2px{padding-right:2px;}
:root ._pb-2px{padding-bottom:2px;}
:root ._pl-2px{padding-left:2px;}
:root ._fd-row{flex-direction:row;}
:root ._gap-1316331230{gap:var(--space-25);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-137134013{color:var(--color-7);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-233016078{font-weight:var(--f-we-2);}
:root ._ls-167743997{letter-spacing:var(--f-21-2);}
:root ._fos-229441158{font-size:var(--f-si-2);}
:root ._lh-222976511{line-height:var(--f-li-2);}
:root ._tt-uppercase{text-transform:uppercase;}
:root ._col-43818184{color:var(--color-17);}
:root ._col-675002279{color:var(--color);}
:root ._col-43816448{color:var(--color-31);}
:root ._col-43818401{color:var(--color-10);}
:root ._fow-233016140{font-weight:var(--f-we-4);}
:root ._ls-167744059{letter-spacing:var(--f-21-4);}
:root ._fos-229441220{font-size:var(--f-si-4);}
:root ._lh-222976573{line-height:var(--f-li-4);}
@media screen and (min-width: 661px) { :root:root:root ._ff-_gtXs_299667014{font-family:var(--f-fa);} }
@media screen and (min-width: 661px) { :root:root:root ._fow-_gtXs_600{font-weight:600;} }
@media screen and (min-width: 661px) { :root:root:root ._ls-_gtXs_167744152{letter-spacing:var(--f-21-7);} }
@media screen and (min-width: 661px) { :root:root:root ._fos-_gtXs_229441313{font-size:var(--f-si-7);} }
@media screen and (min-width: 661px) { :root:root:root ._lh-_gtXs_222976666{line-height:var(--f-li-7);} }